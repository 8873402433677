import React, { Fragment, useEffect, useState } from 'react';
import { Request } from '../../api/Request';
import getTasks from './getTasks';
import LocationJob from './LocationJob';
import { Switch } from 'antd';

interface LocationJobs2Props {
  location: string;
  onDeleteJob: (job: string) => void;
}

export interface JobsAtLocation {
  _id: string;
  tour?: {
    _id: string;
    technician?: { name: string };
    createdAt?: string;
    createdBy?: { name: string };
    changedAt?: string;
    changedBy?: { name: string };
    responseAt?: string;
    responseBy?: { name: string };
    tag: number;
  };
  planedAt?: string;
  failed?: boolean;
  tasks?: Record<string, boolean>;
  state?: string;
  reason?: string;
  taskStates?: Record<string, any>;
}

export default function LocationJobs2({
  location,
  onDeleteJob,
}: LocationJobs2Props) {
  const [jobs, setJobs] = useState<JobsAtLocation[]>();

  useEffect(() => {
    if (location) {
      Request.get('locations', location, 'jobs', { page: 0 }).then((j) => {
        setJobs(j);
      });
    }
  }, [location]);

  const [tasks, setTasks] = useState<any>();
  useEffect(() => {
    async function fetchData() {
      const headerTasks = await getTasks();
      setTasks(headerTasks);
    }
    fetchData();
  }, []);

  console.log('LocationJobs2', jobs?.length);

  const [filterActive, setFilterActive] = useState<boolean>(true);

  return (
    <Fragment>
      <div className='row mb-3'>
        <div className='col col-12'>
          <div className='row table-divider'>
            <div className='col'>
              <span>Jobs</span>
            </div>
            <div className='col'>
              <div className='float-right'>
                <Switch
                  checked={!filterActive}
                  title='Bearbeiten'
                  onChange={(checked) => setFilterActive(!checked)}
                />
                <span
                  style={{
                    marginLeft: '8px',
                  }}
                >
                  Alle anzeigen
                </span>
              </div>
            </div>
          </div>
          <div className=''>
            {!(jobs && jobs.length > 0) && (
              <span>Für diesen Standort wurden noch keine Jobs geplant!</span>
            )}
            {jobs && jobs.length > 0
              ? jobs.map((job) => (
                  <LocationJob
                    filterSystem={filterActive}
                    tasks={tasks}
                    key={job._id}
                    job={job}
                    onDeleteJob={onDeleteJob}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
