import { Input, Select } from 'antd';
import { useField } from 'formik';
import { forEach, set } from 'lodash';
import { name } from 'mustache';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface StatusSelectProps {
  // status: string | undefined;
  // value: string;
  // onChange: (value: string) => void;
  // onChangeOther: (other: string) => void;
  // onChangeReason: (reason: string) => void;
  // other: string;
  // reason: string;
  name: string;
  setOnMixed?: string;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatusSelect: FunctionComponent<StatusSelectProps> = ({
  name,
  setOnMixed,
}) => {
  const fieldNameStatus = `${name}.status`;
  const fieldNameOther = `${name}.other`;
  const fieldNameReason = `${name}.reason`;

  const [fieldJob, , helpersJob] = setOnMixed
    ? useField(setOnMixed)
    : [undefined, undefined, undefined];
  const [fieldTask, , helpersTask] = useField(name);
  const [fieldStatus, metaStatus] = useField(fieldNameStatus);
  const [fieldOther, metaOther] = useField(fieldNameOther);
  const [fieldReason, metaReason] = useField(fieldNameReason);

  const [, , helperTimestamp] = useField('timestamp');

  const getExtraInputValue = () => {
    if (fieldStatus.value === 'other') return fieldOther.value;
    if (fieldStatus.value === 'not-possible') return fieldReason.value;
    return '';
  };

  return (
    <Wrapper>
      <Select
        status={metaStatus.error !== undefined ? 'error' : undefined}
        style={{ width: '45%' }}
        options={[
          {
            value: 'not-completed-technician-sick',
            label: 'Teilabgeschlossen - Techniker krank',
          },
          {
            value: 'not-made-in-time',
            label: 'Teilabgeschlossen - zeitlich',
          },
          {
            value: 'other',
            label: 'Teilabgeschlossen - anderer Grund',
          },
          { value: 'completed', label: 'Abgeschlossen' },
          { value: 'not-completed', label: 'Nicht abgeschlossen' },
          { value: 'not-possible', label: 'Nicht möglich' },
          { value: 'technician-sick', label: 'Techniker krank' },
          { value: 'mixed', label: 'Pro Prüfart', disabled: true },
          { value: 'planed', label: 'Geplant', disabled: true },
        ]}
        value={fieldStatus.value}
        onBlur={() => {
          helpersTask.setTouched(true);
        }}
        onChange={(a) => {
          const value = fieldTask.value;

          // set value for job
          set(value, 'status', a);
          set(value, 'other', '');
          set(value, 'reason', '');

          // reset adjust working hours
          if (setOnMixed === undefined) {
            set(
              value,
              'actualWorkingHours',
              a === 'completed' ? value.planHours : 0
            );
          } else if (a !== 'completed') {
            set(value, 'actualWorkingHours', undefined);
            set(value, 'transferWorkingHours', undefined);
          }

          // set value also for all tasks
          if (fieldTask.value.tasks !== undefined) {
            forEach(fieldTask.value.tasks, (v, task) => {
              set(value, `tasks.${task}.status`, a);
              set(value, `tasks.${task}.other`, '');
              set(value, `tasks.${task}.reason`, '');
              // reset adjust working hours
              set(value, `tasks.${task}.actualWorkingHours`, undefined);
            });
          }

          helpersTask.setValue(value, false);

          // set mixed status for job
          if (helpersJob) {
            helpersJob.setValue({
              ...fieldJob.value,
              status: 'mixed',
            });
            helpersJob.setTouched(true);
          }

          helperTimestamp.setValue(Date.now());
        }}
      />
      {fieldStatus.value === 'other' || fieldStatus.value === 'not-possible' ? (
        <Input
          status={metaOther.error !== undefined ? 'error' : undefined}
          style={{ width: '45%', marginLeft: 44 }}
          value={getExtraInputValue()}
          onBlur={(e) => {
            helpersTask.setTouched(true);

            const value = fieldTask.value;

            // set value also for all tasks
            if (fieldTask.value.tasks !== undefined) {
              forEach(fieldTask.value.tasks, (v, task) => {
                set(value, `tasks.${task}.status`, 'other');
                set(value, `tasks.${task}.other`, e.currentTarget.value);
                set(value, `tasks.${task}.reason`, '');
              });
            }

            // set mixed status for job
            if (helpersJob) {
              helpersJob.setValue({
                ...fieldJob.value,
                other: '',
                status: 'mixed',
              });
              helpersJob.setTouched(true);
            }
          }}
          onChange={(e) => {
            const value = fieldTask.value;

            // set value for job
            set(value, 'status', fieldStatus.value);

            if (fieldStatus.value === 'other') {
              set(value, 'other', e.currentTarget.value);
              set(value, 'reason', '');
            }

            if (fieldStatus.value === 'not-possible') {
              set(value, 'other', '');
              set(value, 'reason', e.currentTarget.value);
            }

            helpersTask.setValue(value, false);
          }}
          placeholder={'Grund'}
        />
      ) : (
        false
      )}
    </Wrapper>
  );
};

export default StatusSelect;
