import { InputNumber } from 'antd';
import { useField } from 'formik';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Title = styled.p<{
  warning?: boolean;
}>`
  color: ${({ warning }) => (warning ? '#FFC107' : '#009842')};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  padding: 1px 0 0;
  overflow: hidden;
  line-height: 28px;
  margin-right: 12px !important;
`;

const Wrapper = styled.div`
  align-self: flex-end;
  display: flex;
`;

interface TransferWorkingHoursFieldProps {
  name: string;
  title?: string;
  disabled?: boolean;
}

const TransferWorkingHoursField: FunctionComponent<
  TransferWorkingHoursFieldProps
> = ({ name, disabled }) => {
  const fieldName = `${name}.transferWorkingHours`;
  const [field, meta, helpers] = useField(fieldName);
  const status = meta.error;

  const error = status !== undefined;
  const warning = field.value > 0 && !error;

  return (
    <Wrapper>
      <Title warning={warning}>{'StO anpassen'}</Title>
      <InputNumber
        status={error ? 'error' : warning ? 'warning' : undefined}
        style={{ width: 66, marginLeft: 0 }}
        value={field.value}
        onBlur={() => {
          helpers.setTouched(true);
        }}
        onChange={(e) => {
          helpers.setValue(e);
        }}
        min={0}
        decimalSeparator=','
        disabled={disabled}
      />
    </Wrapper>
  );
};

export default TransferWorkingHoursField;
