import './Location.scss';

import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import Table, { SELECTION_TYPE, TableOptions } from '../Table/Table';
import { debounce, filter, pick } from 'lodash';

import { AuthConsumer } from '../../context/AuthContext';
import { Request } from '../../api/Request';
import Search from '../Search/Search';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';
import qs from 'query-string';
import { FILTER_OPERATOR_ALL } from '../Table/Filter';

import { ListVideo, Trash, Plus } from 'lucide-react';
import showUpdateManyModal from './UpdateManyModal';

const defaultOptions = {
  sort: 'address.postalCode',
  desc: false,
  page: 0,
  limit: 25,
  fuzzy: true,
};

type Location = any;

interface MatchParams {
  id: string;
}

interface LocationListProps extends RouteComponentProps<MatchParams> {}

interface LocationListOptions extends TableOptions {
  q?: string | null;
  fuzzy?: boolean;
}

interface LocationListState {
  error?: Error;
  loading: boolean;
  searching: boolean;
  options: LocationListOptions;
  items: Location[];
  selectedItems: Location[];
  maxItems?: number;
}

class LocationList extends Component<LocationListProps, LocationListState> {
  state: LocationListState = {
    loading: true,
    searching: false,
    options: defaultOptions,
    maxItems: 0,
    items: [],
    selectedItems: [],
  };

  handleSearchDebounced = debounce(this.handleSearch.bind(this), 440);

  header(searching: boolean) {
    return [
      {
        key: 'customer.group.name',
        width: 1,
        sort: searching ? false : true,
        filterable: true,
        filterComparators: FILTER_OPERATOR_ALL,
        title: 'Kundengruppe',
      },
      {
        key: 'customer.customernumber',
        width: 1,
        sort: searching ? false : false,
        filterable: true,
        filterComparators: FILTER_OPERATOR_ALL,
        title: 'Kundennummer',
        emptySymbol: ' ',
      },
      {
        key: 'tours',
        width: 1,
        sort: searching ? false : true,
        title: 'Tour',
        noLink: true,
        format: (value: Location) => {
          return (
            <span>
              {value && Array.isArray(value)
                ? value.map(({ tag, _id }: any) => (
                    <span key={_id}>
                      <NavLink to={`/tours/${_id || ''}`} target='_blank'>
                        {tag}
                      </NavLink>{' '}
                    </span>
                  ))
                : false}
            </span>
          );
        },
      },
      {
        key: 'customer.name',
        width: '1-5',
        sort: searching ? false : true,
        filterable: true,
        filterComparators: FILTER_OPERATOR_ALL,
        title: 'Kunde',
      },
      {
        key: 'name',
        width: '1-5',
        sort: searching ? false : true,
        filterable: true,
        filterComparators: FILTER_OPERATOR_ALL,
        title: 'Standort',
      },
      {
        key: 'tag',
        width: '1-25',
        sort: searching ? false : true,
        filterable: true,
        filterComparators: FILTER_OPERATOR_ALL,
        title: 'Standort NR',
      },
      {
        key: 'address.street',
        width: 2,
        sort: searching ? false : true,
        title: 'Straße',
      },
      {
        key: 'address.postalCode',
        width: 1,
        sort: searching ? false : true,
        filterable: true,
        filterComparators: FILTER_OPERATOR_ALL,
        title: 'PLZ',
      },
      {
        key: 'address.city',
        width: 1,
        sort: searching ? false : true,
        filterable: true,
        filterComparators: FILTER_OPERATOR_ALL,
        title: 'Ort',
      },
    ];
  }

  constructor(props: LocationListProps) {
    super(props);
    this.handleOptionsChanged = this.handleOptionsChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.removeSelectedLocations = this.removeSelectedLocations.bind(this);
    this.batchSelectedLocations = this.batchSelectedLocations.bind(this);
  }

  async componentDidMount() {
    await this.loadOptionsFromURL(this.props.location);
    this.loadData();
  }

  loadOptionsFromURL(location: { search: string }) {
    const data = qs.parse(location.search);
    return new Promise<void>((resolve) => {
      const s: LocationListOptions = {
        filters: data && data.filters ? JSON.parse(data.filters as string) : {},
      };

      s.sort = (data.sort as string) || defaultOptions.sort;
      s.page = Number(data.page) || defaultOptions.page;
      s.desc = Boolean(data.desc) || defaultOptions.desc;
      s.limit = Number(data.limit) || defaultOptions.limit;
      s.q = data.q as string;
      s.fuzzy = data.fuzzy ? data.fuzzy === 'true' : defaultOptions.fuzzy;
      this.setState({ options: s }, async () => resolve());
    });
  }

  storeOptionsAtURL() {
    const s = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'q',
      'fuzzy',
    ]);

    const a = {
      ...s,
      filters: JSON.stringify(this.state.options.filters),
    };

    const stringified = qs.stringify(a);

    window.history.replaceState(a, 'safePlan', `?${stringified}`);
  }

  async handleSearch(query: string | undefined) {
    this.setState(
      {
        loading: true,
        options: {
          limit: undefined,
          page: undefined,
          desc: true,
          sort: 'score',
          q: query,
        },
      },
      () => {
        this.storeOptionsAtURL();
        this.loadData();
      }
    );
  }

  async loadData() {
    const s = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'q',
      'fuzzy',
    ]);

    const a = {
      ...s,
      filters: JSON.stringify(this.state.options.filters),
    };

    const result: {
      search: boolean;
      items: any[];
      total: number;
    } = await Request.list('locations', { ...a, lookup: false });
    this.setState({
      loading: false,
      searching: result.search,
      items: result.items,
      maxItems: result.search ? 0 : result.total,
    });
  }

  handleOptionsChanged(opts: LocationListOptions) {
    this.setState(
      {
        loading: true,
        options: opts,
      },
      () => {
        this.storeOptionsAtURL();
        this.loadData();
      }
    );
  }

  handleSelectionChanged(locationsToSet: Location[], selection: boolean) {
    const { selectedItems } = this.state;
    if (selection) {
      this.setState({
        selectedItems: selectedItems.concat(locationsToSet),
      });
    } else {
      const ids = locationsToSet.map((l) => l._id);
      const value = filter(selectedItems, (l) => !ids.includes(l._id));
      this.setState({ selectedItems: value });
    }
  }

  async batchSelectedLocations() {
    const { selectedItems } = this.state;
    const ids = selectedItems.map((l) => l._id);
    await showUpdateManyModal(ids);
    this.loadData();
  }

  async removeSelectedLocations() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Sind Sie sich sicher?')) {
      try {
        this.setState({ loading: true });
        const { selectedItems } = this.state;
        await Promise.all(
          selectedItems.map((s) => {
            return Request.delete('locations', s._id);
          })
        );
        this.loadData();
      } catch (error: any) {
        this.setState({
          loading: false,
          error,
        });
      }
    }
  }

  render() {
    const { items, options, maxItems, selectedItems, loading } = this.state;
    return (
      <AuthConsumer>
        {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
          <div className='location container-inner container-inner-list'>
            <div className='page-header row justify-content-between'>
              <div className='col col-12 col-md-6'>
                <h1 className='page-title'>Standorte verwalten</h1>
              </div>
              <div className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
                <Search
                  value={options.q}
                  onChange={this.handleSearchDebounced}
                />
                {hasPermission(['location:write']) === true ? (
                  <NavLink
                    className='float-right'
                    to={`/administration/locations/new`}
                  >
                    <Plus />
                    Hinzufügen
                  </NavLink>
                ) : null}
                {hasPermission(['location:delete-multi']) === true &&
                selectedItems.length > 0 ? (
                  <div onClick={this.batchSelectedLocations}>
                    <ListVideo />
                    {selectedItems.length > 1 ? (
                      <p>{selectedItems.length} Standorte ändern</p>
                    ) : (
                      <p>Ein Standort ändern</p>
                    )}
                  </div>
                ) : null}
                {hasPermission(['location:delete-multi']) === true &&
                selectedItems.length > 0 ? (
                  <div onClick={this.removeSelectedLocations}>
                    <Trash />
                    <p>Ausgewählte löschen</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='row page-content'>
              <div className='col col-12'>
                <Table
                  loading={loading}
                  filterable={true}
                  selectable={
                    hasPermission(['location:delete-multi']) === true
                      ? SELECTION_TYPE.selectAll
                      : SELECTION_TYPE.disabled
                  }
                  options={options}
                  header={this.header(this.state.searching)}
                  items={items}
                  maxItems={maxItems}
                  handleOptionsChanged={this.handleOptionsChanged}
                  selection={selectedItems}
                  handleSelectionChanged={this.handleSelectionChanged}
                  link={'/administration/locations'}
                />
              </div>
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(LocationList);
